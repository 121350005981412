import {redRoom, orangeRoom, yellowRoom, greenRoom, northRoom, capacitationRoom, openspacitoRoom, cocinaRoom} from './Rooms';

const buildTalk = (title, author, room, details, feedbackLink, meetLink) => {
  return {
    title,
    author,
    room,
    details,
    feedbackLink,
    meetLink,
  }
};

const schedule = [
  {
    time: '15.00 PM',
    description: 'Introducción al radar 🔗',
    break: true,
    link: cocinaRoom.meetLink,
  },
  {
    time: '15.20 PM',
    talks: [
      buildTalk('Buenos Sueldos - Sala Norte', 'Gise, Jazz, Nahue G + 6 personas presenciales / virtuales', northRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Buenos Sueldos - Sala Roja', 'Nahue V, Feche + 7 personas presenciales / virtuales', redRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Buenos Sueldos - Sala Amarilla', 'Pablo A, Lucas G + 7 personas presenciales / virtuales', yellowRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Buenos Sueldos - Sala Naranja', 'Lowy, Diego P + 7 personas presenciales / virtuales', orangeRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Buenos Sueldos - Sala Verde', 'Lauta, Maru + 7 personas presenciales / virtuales', greenRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Buenos Sueldos - Openspacito', 'Gabi, Joe + 7 personas presenciales / virtuales', openspacitoRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Buenos Sueldos - Cocina', '9 personas presenciales', cocinaRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179')
    ]
  },
  {
    time: '16.00 PM',
    description: 'Break',
    break: true
  },
  {
    time: '16.05 PM',
    talks: [
      buildTalk('Crecimiento - Sala Norte', 'Jaz, Joe, Joa, Cande, Igal, Ernest, Franco, Nahue G', northRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Crecimiento - Sala Roja', 'Pablo A, Gaby, Mica, Luca, SantiP, Hernan, Juli Romero', redRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Crecimiento - Sala Amarilla', 'Nahue V, Maiu, Bianca, Angel, Gaston, Edson, Charlie', orangeRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Gestion Participativa - Sala Naranja', 'Feche, Lucas G, Lowy, Titi, Meli, Fedex', yellowRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Gestion Participativa - Sala Verde', 'Gise, Maru, Mori, Santi Nodar, Achus, Belu', greenRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Gestion Participativa - Openspacito', 'Lauta, W-etc, Lu Alonso, Darius, Nicky, Diega', openspacitoRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Gestion Participativa - Cocina', 'Marta, Diego P, Rick, Debi, Juli Rossi', cocinaRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179')
    ]
  },
  {
    time: '16.45 PM',
    description: 'Break',
    break: true
  },
  {
    time: '16.50 PM',
    talks: [
      buildTalk('Calidad Humana - Sala Norte', 'Cande, Lu Alonso, Feche, Mori, Meli, Gise', northRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Calidad humana - Sala Roja', 'Maiu, Lauta, Bianca, W-etc, Mica, Jaz, Joa', redRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Proyectos interesantes - Sala Amarilla', 'Lucas G, Gaby, Diego, Titi, Belu, Marta, Fedex', yellowRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Proyectos interesantes - Sala Naranja', 'Nahue V, Maru, Lowy, Franco, Charlie, Nicky', orangeRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Calidad tecnica - Sala Verde', 'Hernan, Achus, Luca, Juli Rossi, Angel, Edson, Nahue G', greenRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Calidad tecnica - Openspacito', 'Igal, Debi, Santi Nodar, Joel, Pablo A, Diega', openspacitoRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179'),
      buildTalk('Calidad tecnica 2 - Cocina', 'Juli Romero, SantiP, Darius, Ernest, Rick, Gasti', cocinaRoom, '', 'https://miro.com/welcomeonboard/ZVo1Nm1Rb2RmeUJLV3h3Y1RoUUlFekRPeVRFVU9OWG1BMEJ2VzNYcVhEOXVNQWtzS1FCbTNveFJhN3RObmdNUHwzMDc0NDU3MzQ4NzU3MjQzNTM5fDI=?share_link_id=739523199179')
    ]
  },
  {
    time: '17.30 PM',
    description: 'Break',
    break: true
  },
  {
    time: '17.35 PM',
    description: 'Cierre 🔗',
    break: true,
    link: capacitationRoom.meetLink,
  },
];

export default schedule;
